<script setup lang="ts">
import { isFontVariantLoaded, isFontVariantLoadedTracked, trackFontVariantLoaded } from '@@/bits/font_loading'
import { computed, onMounted, ref } from 'vue'
import InlineSvg from 'vue-inline-svg'

type NumberOfPaths = number
const COLORED_ICONS: Record<string, NumberOfPaths> = {
  'amazon-color': 2,
  'paypal-color': 3,
  'discover-color': 3,
  'jcb-color': 5,
  'mastercard-color': 3,
  'unionpay-color': 5,
  'amex-color': 2,
  'dinersclub-color': 3,
  'visa-color': 3,
  google: 4,
  windows: 4,
  classlink: 4,
  skoletube: 2,
  twitter: 1,
  google_drive_color: 6,
  clever_blue: 1,
  clever_white: 1,
}
// @see https://material.io/design/usability/bidirectionality.html#mirroring-elements
const ORICONS_MIRRORING = [
  // Arrows
  'back',
  'arrow_right',
  'highlight_text',
  'arrow_left',
  'arrow_right',
  'chevron_left_center',
  'chevron_left_left',
  'chevron_right_center',
  'chevron_right_right',
  // Text-related
  'attachment_caption',
  'subscript',
  'superscript',
  'bulleted_list',
  'numbered_list',
  'clipboard',
  'clipboard_outline',
  'comment',
  'quote',
  'enlarge',
  'dock',
  'expand',
  'padlets',
  'send_back',
  'send_front',
  'select_all',

  // Section-related
  'section_arrow_left',
  'section_arrow_right',
  // Movement
  'transfer',
  'external_link',
  'external_link_outline',
  'grid_link',
  'new_frame_above',
  'new_frame_below',
  'duplicate_outline',
  'clear_frame',
  'copy_outline',
  'scissors',
  'send_to_front_outline',
  'send_to_back_outline',

  // Passage of time
  'undo',
  'redo',
  // Others
  'note_filled',
  'note_outline',
]
const WBICONS_MIRRORING = [
  // Text-related/Movement
  'highlighter',
  'eraser',
  // Passage of time
  'undo',
  'redo',
  // Others
  'postnote',
]
/**
 * For list of icons:
 *
 *  - Go to https://icomoon.io/app/#/projects
 *  - Load appropriate project (*Immaterial* or *Oricons*)
 *  - Click *Generate Font* at the bottom
 *
 */

type IconSize = 'small' | 'normal' | 'large'

const props = withDefaults(
  defineProps<{
    /**
     * If dir="rtl" is included, left-right arrows buttons are flipped
     * @default ltr
     */
    dir?: 'ltr' | 'rtl' | 'auto'
    /**
     * Name of the icon to load
     */
    name: string
    /**
     * Determines the size of the icon
     */
    size?: number | IconSize
    /**
     * Determines if icon should be hidden from screen reader (i.e. only decorative). Default is true.
     */
    ariaHidden?: boolean
    /**
     * Determines the icon font to load
     */
    font?: 'immaterial' | 'oricon' | 'wbicons'
  }>(),
  {
    dir: 'auto',
    size: 'normal',
    ariaHidden: true,
    font: 'oricon',
  },
)

defineEmits<{
  (name: 'click', event: MouseEvent): void
}>()

const isFontLoaded = ref(false)

const isColoredIcon = computed((): boolean => Object.keys(COLORED_ICONS).includes(props.name))

const isMirroringEnabled = computed((): boolean => {
  if (!props.name) return false
  return (
    (props.font === 'oricon' && ORICONS_MIRRORING.includes(props.name)) ||
    (props.font === 'wbicons' && WBICONS_MIRRORING.includes(props.name))
  )
})

// @see https://material.io/design/usability/bidirectionality.html#mirroring-elements
//
// Use `inline-block` to force the element to be transformable:
// https://stackoverflow.com/questions/14883250/css-transform-doesnt-work-on-inline-elements
const rtlTransformClasses = computed((): string => {
  if (!isMirroringEnabled.value) return ''
  if (props.dir === 'rtl') return 'inline-block transform -scale-x-100'
  if (props.dir === 'auto') return 'inline-block rtl:transform rtl:-scale-x-100'
  return ''
})

const numOfPaths = computed((): number => COLORED_ICONS[props.name])

const iconClass = computed((): string => {
  if (props.font === 'immaterial') {
    return 'immaterial-icons'
  } else if (props.font === 'wbicons') {
    return 'wbicons'
  }
  return 'oricon'
})

const iconSize = computed((): number => {
  let sizeNumber = 24
  if (typeof props.size === 'number') {
    sizeNumber = props.size
  } else if (props.size === 'small') {
    sizeNumber = 18
  } else if (props.size === 'large') {
    sizeNumber = 32
  }
  return sizeNumber
})

const styles = computed((): string => {
  const sizeNumber = iconSize.value
  if (!isFontLoaded.value) {
    return `visibility: hidden; font-size: ${sizeNumber}px; min-width:${sizeNumber}px; max-width:${sizeNumber}px; width: ${sizeNumber}px; height: ${sizeNumber}px;`
  }

  return `font-size: ${sizeNumber}px; max-width:${sizeNumber}px; width: ${sizeNumber}px; height: ${sizeNumber}px; overflow: visible;`
})

const isEmoji = computed((): boolean => {
  // eslint-disable-next-line prefer-regex-literals
  const emojiRegex = new RegExp(
    '^(\\p{RI}\\p{RI}|\\p{Extended_Pictographic}(\\p{Emoji_Modifier}|\\u{FE0F}\\u{20E3}?|[\\u{E0020}-\\u{E007E}]+\\u{E007F})?(\\u{200D}(\\p{RI}\\p{RI}|\\p{Emoji}(\\p{Emoji_Modifier}|\\u{FE0F}\\u{20E3}?|[\\u{E0020}-\\u{E007E}]+\\u{E007F})?))*)',
    'u',
  )

  return props.name.match(emojiRegex) !== null
})

onMounted(() => {
  const fontName = props.font == 'immaterial' ? 'immaterial' : 'oricons'
  const fontVariant = `24px ${fontName}`
  if (isFontVariantLoadedTracked(fontVariant)) {
    isFontLoaded.value = isFontVariantLoaded(fontVariant)
  } else {
    trackFontVariantLoaded(`24px ${fontName}`).then((isTrackedFontLoaded) => {
      isFontLoaded.value = isTrackedFontLoaded
    })
  }
})
</script>

<script lang="ts">
export default {}
</script>

<template>
  <span v-if="isColoredIcon" :class="[iconClass, `icon-${name}`, rtlTransformClasses]" :style="styles">
    <template v-for="num in numOfPaths"><span :key="num" :class="`path${num}`"></span> </template>
  </span>
  <span
    v-else-if="iconClass === 'oricon' && !isEmoji"
    class="flex items-center justify-center"
    :style="`width: ${iconSize}px; height: ${iconSize}px`"
  >
    <InlineSvg
      :class="['fill-current', rtlTransformClasses]"
      style="overflow: visible"
      :src="`https://padlet.net/icons/svg/oricons/${props.name}.svg`"
      :width="iconSize"
      :height="iconSize"
      :aria-hidden="String(ariaHidden)"
      alt=""
      @click="$emit('click', $event)"
    />
  </span>
  <i
    v-else
    :class="[iconClass, rtlTransformClasses]"
    :style="styles"
    :aria-hidden="String(ariaHidden)"
    @click="$emit('click', $event)"
    >{{ name }}</i
  >
</template>

<style lang="scss" scoped>
@import '@@/styles/3/partials/fonts/material_icons';
@import '@@/styles/3/partials/fonts/oricons';
@import '@@/fonts/wbicons';

.immaterial-icons {
  @include immaterial-icon;
}

.oricon {
  @include oricon;
}

.wbicons {
  @include wbicons;
}
</style>
