// @file Functions related to tracking font loading with browser's document.font
// Font variant string is an accepted input of document.fonts.load. e.g. "16px Arial"
import environment from '@@/bits/environment'

declare global {
  interface Document {
    fonts?: any
  }
}

const trackedFonts: Record<string, boolean> = {}

export function isFontVariantLoadedTracked(fontVariant: string): boolean {
  return trackedFonts[fontVariant] !== undefined
}
export function isFontVariantLoaded(fontVariant): boolean {
  return trackedFonts[fontVariant]
}

export async function trackFontVariantLoaded(fontVariant: string): Promise<boolean> {
  // On dev/e2e environments, the OzIcon is sometimes loaded before the fonts so even the fonts are loaded after that, the icon's visibility is still hidden
  // More details: document.fonts.load return an empty array, so this function always returns false
  if (environment === 'development' || environment === 'e2e_test' || environment === 'e2e_ci') {
    trackedFonts[fontVariant] = true
    return true
  }
  if (trackedFonts[fontVariant]) {
    return trackedFonts[fontVariant]
  }
  if (!document.fonts && document.readyState === 'complete') {
    // If FontFaceSet API is not supported, we fallback to DOM ready state
    trackedFonts[fontVariant] = true
    return true
  }

  if (!document.fonts) {
    const result = await new Promise<boolean>((resolve) => {
      window.addEventListener('DOMContentLoaded', () => {
        // If FontFaceSet API is not supported, we fallback to DOM ready state
        trackedFonts[fontVariant] = true
        resolve(true)
      })
    })
    return result
  }
  if (document.fonts) {
    const fontsLoaded = await document.fonts.load(fontVariant)
    trackedFonts[fontVariant] = fontsLoaded.length > 0
    return trackedFonts[fontVariant]
  }
  return true
}
